import React from "react";

class Services extends React.Component {
    render() {
        return (
            <section
                id="services"
                className={`services ${
                    this.props.active_section === "#services"
                        ? "section-show"
                        : ""
                }`}
            >
                <div className="container">
                    <div className="section-title">
                        <h2>Services</h2>
                        <p>My Services</p>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                            <div className="icon-box">
                                <div className="icon">
                                    <i className="bx bxl-python bx-tada"></i>
                                </div>
                                <h4>
                                    <a href="#header">Odoo ERP</a>
                                </h4>
                                <p>
                                    Reduce complexity through standardization
                                    and Effortlessly manages all your
                                    information.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                            <div className="icon-box">
                                <div className="icon">
                                    <i className="bx bxs-business bx-tada"></i>
                                </div>
                                <h4>
                                    <a href="#header">Business Counsulting</a>
                                </h4>
                                <p>
                                    save your time and money with us and plan
                                    your future to improve your Business.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
                            <div className="icon-box">
                                <div className="icon">
                                    <i className="bx bx-server bx-tada"></i>
                                </div>
                                <h4>
                                    <a href="#header">
                                        Installation &amp; Deploy
                                    </a>
                                </h4>
                                <p>
                                    We do a installation and deployment on live
                                    time server to use dynamically services.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                            <div className="icon-box">
                                <div className="icon">
                                    <i className="bx bx-world bx-tada"></i>
                                </div>
                                <h4>
                                    <a href="#header">Website</a>
                                </h4>
                                <p>
                                    Engaging, purposeful, and creative.
                                    Innovation starts with us.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                            <div className="icon-box">
                                <div className="icon">
                                    <i className="bx bxl-react bx-tada"></i>
                                </div>
                                <h4>
                                    <a href="#header">React</a>
                                </h4>
                                <p>
                                    very large community support is always
                                    around you.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                            <div className="icon-box">
                                <div className="icon">
                                    <i className="bx bx-support bx-tada"></i>
                                </div>
                                <h4>
                                    <a href="#header">
                                        Support &amp; Maintenance
                                    </a>
                                </h4>
                                <p>
                                    We don’t leave your hands even after
                                    implementation. provide 24*7 support.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Services;
