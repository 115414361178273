import React from 'react';
import {
    RiBasketballLine,
    RiGamepadLine,
    RiBookLine,
    RiFilmLine,
    RiRoadMapLine,
    RiFileMusicLine,
    RiCodeSSlashLine,
    RiFundsLine,
} from "react-icons/ri";

class About extends React.Component {

    render() {
        return (
            <>
                <section
                    id="about"
                    className={
                        this.props.active_section === "#about"
                            ? "about section-show"
                            : "about"
                    }
                >
                    <div className="about-me container">
                        <div className="section-title">
                            <h2>About</h2>
                            <p>Learn more about me</p>
                        </div>

                        <div className="row">
                            <div className="col-lg-4" data-aos="fade-right">
                                <img
                                    src="/images/resume_image.jpg"
                                    className="img-fluid"
                                    alt=""
                                />
                            </div>
                            <div
                                className="col-lg-8 pt-4 pt-lg-0 content"
                                data-aos="fade-left"
                            >
                                <h3>Python &amp; Odoo developer</h3>
                                <p className="font-italic">
                                    I’m an innovative software engineer,
                                    managing all aspects of the development
                                    process for small to medium-sized companies.
                                </p>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <ul>
                                            <li>
                                                <i
                                                    className="bx bxs-chevron-right bx-fade-right"
                                                />{" "}
                                                <strong>Birthday:</strong> 12
                                                March 1998
                                            </li>
                                            <li>
                                                <i
                                                    className="bx bxs-chevron-right bx-fade-right"
                                                />{" "}
                                                <strong>Phone:</strong> +91
                                                9099960912
                                            </li>
                                            <li>
                                                <i
                                                    className="bx bxs-chevron-right bx-fade-right"
                                                />{" "}
                                                <strong>City:</strong> vadodara,
                                                Gujarat
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6">
                                        <ul>
                                            <li>
                                                <i
                                                    className="bx bxs-chevron-right bx-fade-right"
                                                />{" "}
                                                <strong>Age:</strong>{" "}
                                                {new Date().getFullYear() -
                                                    1998}
                                            </li>
                                            <li>
                                                <i
                                                    className="bx bxs-chevron-right bx-fade-right"
                                                />{" "}
                                                <strong>Degree:</strong> B.E
                                                [IT]
                                            </li>
                                            <li>
                                                <i
                                                    className="bx bxs-chevron-right bx-fade-right"
                                                />{" "}
                                                <strong>Availablity:</strong>{" "}
                                                Full/Part &amp; Freelancing
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <p>
                                    I am proficient in Python, JavaScript, React
                                    Native, HTML and CSS. Of these programming
                                    languages, I feel most comfortable working
                                    with Python, Javascript. In my previous
                                    role, I worked mainly with Python to create
                                    applications that worked across multiple
                                    platforms. I also used React to develop a
                                    new website that worked with multiple
                                    devices.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="counts container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                <div className="count-box">
                                    <i className="icofont-simple-smile"></i>
                                    <span data-toggle="counter-up">232</span>
                                    <p>Happy Clients</p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 mt-5 mt-md-0">
                                <div className="count-box">
                                    <i className="icofont-document-folder"></i>
                                    <span data-toggle="counter-up">521</span>
                                    <p>Projects</p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
                                <div className="count-box">
                                    <i className="icofont-live-support"></i>
                                    <span data-toggle="counter-up">1,463</span>
                                    <p>Hours Of Support</p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
                                <div className="count-box">
                                    <i className="icofont-users-alt-5"></i>
                                    <span data-toggle="counter-up">15</span>
                                    <p>Hard Workers</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="skills container">
                        <div className="section-title">
                            <h2>Skills</h2>
                        </div>

                        <div className="row skills-content">
                            <div className="col-lg-6">
                                <div className="progress">
                                    <span className="skill">
                                        Python <i className="val">100%</i>
                                    </span>
                                    <div className="progress-bar-wrap">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{ width: "100%" }}
                                            // aria-valuenow="100"
                                            // aria-valuemin="0"
                                            // aria-valuemax="100"
                                        ></div>
                                    </div>
                                </div>

                                <div className="progress">
                                    <span className="skill">
                                        React <i className="val">70%</i>
                                    </span>
                                    <div className="progress-bar-wrap">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{ width: "70%" }}
                                            // aria-valuenow="70"
                                            // aria-valuemin="0"
                                            // aria-valuemax="100"
                                        ></div>
                                    </div>
                                </div>

                                <div className="progress">
                                    <span className="skill">
                                        Photoshop <i className="val">55%</i>
                                    </span>
                                    <div className="progress-bar-wrap">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{ width: "55%" }}
                                            // aria-valuenow="55"
                                            // aria-valuemin="0"
                                            // aria-valuemax="100"
                                        ></div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="progress">
                                    <span className="skill">
                                        JavaScript <i className="val">91%</i>
                                    </span>
                                    <div className="progress-bar-wrap">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{ width: "91%" }}
                                            // aria-valuenow="91"
                                            // aria-valuemin="0"
                                            // aria-valuemax="100"
                                        ></div>
                                    </div>
                                </div>

                                <div className="progress">
                                    <span className="skill">
                                        HTML <i className="val">100%</i>
                                    </span>
                                    <div className="progress-bar-wrap">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{ width: "100%" }}
                                            // aria-valuenow="100"
                                            // aria-valuemin="0"
                                            // aria-valuemax="100"
                                        ></div>
                                    </div>
                                </div>

                                <div className="progress">
                                    <span className="skill">
                                        CSS <i className="val">90%</i>
                                    </span>
                                    <div className="progress-bar-wrap">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{ width: "90%" }}
                                            // aria-valuenow="90"
                                            // aria-valuemin="0"
                                            // aria-valuemax="100"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="interests container">
                        <div className="section-title">
                            <h2>Interests</h2>
                        </div>

                        <div className="row">
                            <div className="col-lg-3 col-md-4">
                                <div className="icon-box">
                                    <RiBasketballLine
                                        color="rgb(255, 187, 44)"
                                        size="32px"
                                    />
                                    <h3>Cricket</h3>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 mt-4 mt-md-0">
                                <div className="icon-box">
                                    <RiGamepadLine
                                        color="rgb(85, 120, 255)"
                                        size="32px"
                                    />
                                    <h3>Video Games</h3>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 mt-4 mt-md-0">
                                <div className="icon-box">
                                    <RiBookLine
                                        color="rgb(232, 3, 104)"
                                        size="32px"
                                    />
                                    <h3>Comic Books</h3>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 mt-4 mt-lg-0">
                                <div className="icon-box">
                                    <RiFilmLine
                                        color="rgb(227, 97, 255)"
                                        size="32px"
                                    />
                                    <h3>Movie</h3>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 mt-4">
                                <div className="icon-box">
                                    <RiRoadMapLine
                                        color="rgb(71, 174, 255)"
                                        size="32px"
                                    />
                                    <h3>Travel</h3>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 mt-4">
                                <div className="icon-box">
                                    <RiFileMusicLine
                                        color="rgb(255, 167, 110)"
                                        size="32px"
                                    />
                                    <h3>Music</h3>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 mt-4">
                                <div className="icon-box">
                                    <RiCodeSSlashLine
                                        color="rgb(17, 219, 207)"
                                        size="32px"
                                    />
                                    <h3>Coding</h3>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 mt-4">
                                <div className="icon-box">
                                    <RiFundsLine
                                        color="rgb(66, 51, 255)"
                                        size="32px"
                                    />
                                    <h3>Stock trading</h3>
                                </div>
                            </div>
                            {/* <div className="col-lg-3 col-md-4 mt-4">
                                <div className="icon-box">
                                    <i
                                        className="ri-anchor-line"
                                        style={{color: "#b2904f"}}
                                    ></i>
                                    <h3>Dirada Pack</h3>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 mt-4">
                                <div className="icon-box">
                                    <i
                                        className="ri-disc-line"
                                        style={{color: "#b20969"}}
                                    ></i>
                                    <h3>Moton Ideal</h3>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 mt-4">
                                <div className="icon-box">
                                    <i
                                        className="ri-base-station-line"
                                        style={{color: "#ff5828"}}
                                    ></i>
                                    <h3>Verdo Park</h3>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 mt-4">
                                <div className="icon-box">
                                    <i
                                        className="ri-fingerprint-line"
                                        style={{color: "#29cc61"}}
                                    ></i>
                                    <h3>Flavor Nivelanda</h3>
                                </div>
                            </div> */}
                        </div>
                    </div>

                    {/* <div className="testimonials container">
                        <div className="section-title">
                            <h2>Testimonials</h2>
                        </div>

                        <div className="owl-carousel testimonials-carousel">
                            <div className="testimonial-item">
                                <p>
                                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                    Proin iaculis purus consequat sem cure digni
                                    ssim donec porttitora entum suscipit
                                    rhoncus. Accusantium quam, ultricies eget
                                    id, aliquam eget nibh et. Maecen aliquam,
                                    risus at semper.
                                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                </p>
                                <img
                                    src="assets/img/testimonials/testimonials-1.jpg"
                                    className="testimonial-img"
                                    alt=""
                                />
                                <h3>Saul Goodman</h3>
                                <h4>Ceo &amp; Founder</h4>
                            </div>

                            <div className="testimonial-item">
                                <p>
                                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                    Export tempor illum tamen malis malis eram
                                    quae irure esse labore quem cillum quid
                                    cillum eram malis quorum velit fore eram
                                    velit sunt aliqua noster fugiat irure amet
                                    legam anim culpa.
                                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                </p>
                                <img
                                    src="assets/img/testimonials/testimonials-2.jpg"
                                    className="testimonial-img"
                                    alt=""
                                />
                                <h3>Sara Wilsson</h3>
                                <h4>Designer</h4>
                            </div>

                            <div className="testimonial-item">
                                <p>
                                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                    Enim nisi quem export duis labore cillum
                                    quae magna enim sint quorum nulla quem
                                    veniam duis minim tempor labore quem eram
                                    duis noster aute amet eram fore quis sint
                                    minim.
                                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                </p>
                                <img
                                    src="assets/img/testimonials/testimonials-3.jpg"
                                    className="testimonial-img"
                                    alt=""
                                />
                                <h3>Jena Karlis</h3>
                                <h4>Store Owner</h4>
                            </div>

                            <div className="testimonial-item">
                                <p>
                                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                    Fugiat enim eram quae cillum dolore dolor
                                    amet nulla culpa multos export minim fugiat
                                    minim velit minim dolor enim duis veniam
                                    ipsum anim magna sunt elit fore quem dolore
                                    labore illum veniam.
                                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                </p>
                                <img
                                    src="assets/img/testimonials/testimonials-4.jpg"
                                    className="testimonial-img"
                                    alt=""
                                />
                                <h3>Matt Brandon</h3>
                                <h4>Freelancer</h4>
                            </div>

                            <div className="testimonial-item">
                                <p>
                                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                    Quis quorum aliqua sint quem legam fore sunt
                                    eram irure aliqua veniam tempor noster
                                    veniam enim culpa labore duis sunt culpa
                                    nulla illum cillum fugiat legam esse veniam
                                    culpa fore nisi cillum quid.
                                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                </p>
                                <img
                                    src="assets/img/testimonials/testimonials-5.jpg"
                                    className="testimonial-img"
                                    alt=""
                                />
                                <h3>John Larson</h3>
                                <h4>Entrepreneur</h4>
                            </div>
                        </div>
                    </div> */}
                </section>
            </>
        );
    }
}

export default About
