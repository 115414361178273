const SocialLinks = {
    'website': 'mayank-patel.tech',
    'email': 'mayankpatel3555@gmail.com',
    'phoneto': 'tel:919099960912',
    'phone': '+91 9099960912',
    'address': 'https://goo.gl/maps/7dZvzNZPCnJx2X2L8',
    'twitter': 'https://twitter.com/mayankpatel3555',
    'facebook': 'https://www.facebook.com/m.patel.jaysardar/',
    'instagram': 'https://www.instagram.com/mayank__ptl/?hl=en',
    'skype': 'skype:mayankpatel3555?chat',
    'linkedin': 'https://www.linkedin.com/in/mayank-patel-1b3b07118/',
}

export default SocialLinks
