import React from 'react';

class Resume extends React.Component {

    render() {
        return (
            <section
                id="resume"
                className={
                    this.props.active_section === "#resume"
                        ? "resume section-show"
                        : "resume"
                }
            >
                <div className="container">
                    <div className="section-title">
                        <h2>Resume</h2>
                        <p>Check My Resume</p>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <h3 className="resume-title">Sumary</h3>
                            <div className="resume-item pb-0">
                                <h4>mayank Patel</h4>
                                <p>
                                    <em>
                                        To contribute my talents and innovative
                                        skills for development of the company
                                        and to shape myself as a sagacious
                                        professional while serving the company.
                                    </em>
                                </p>
                                <div>
                                    <ul>
                                        <li>Surat, Gujarat, India</li>
                                        <li>+91 9099960912</li>
                                        <li>mayankpatel3555@gmail.com</li>
                                    </ul>
                                </div>
                            </div>

                            <h3 className="resume-title">Education</h3>
                            <div className="resume-item">
                                <h4>
                                    Bachelor of Engineering &amp; Informtion
                                    Technology
                                </h4>
                                <h5>2015 - 2019</h5>
                                <p>
                                    <em>
                                        Government Engineering College,
                                        Bhavnagar, Gujarat, IN
                                    </em>
                                </p>
                                <div>
                                    <ul>
                                        <li>8.10 CGPA</li>
                                    </ul>
                                </div>
                            </div>

                            <h3 className="resume-title">Linguistic Skill</h3>
                            <div className="resume-item">
                                <h4>Gujarati</h4>
                                <p>
                                    <em>Native language</em>
                                </p>
                            </div>
                            <div className="resume-item">
                                <h4>Hindi</h4>
                                <p>
                                    <em>Nation language</em>
                                </p>
                            </div>
                            <div className="resume-item">
                                <h4>English</h4>
                                <h5>2019 - 2021</h5>
                                <p>
                                    <em>IELTS certificate (6.5 band)</em>
                                </p>
                                <div>
                                    <ul>
                                        <li>Listening : 7.5</li>
                                        <li>Reading : 6.5</li>
                                        <li>Writing : 5.5</li>
                                        <li>Speaking : 6</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h3 className="resume-title">Technical Skill</h3>
                            <div className="resume-item">
                                <h4>Python</h4>
                            </div>
                            <div className="resume-item">
                                <h4>JavaScript</h4>
                            </div>
                            <div className="resume-item">
                                <h4>HTML</h4>
                            </div>
                            <div className="resume-item">
                                <h4>CSS</h4>
                            </div>
                            <div className="resume-item">
                                <h4>React</h4>
                            </div>
                            <div className="resume-item">
                                <h4>Others</h4>
                                <p>C, C++, Java</p>
                            </div>
                            <h3 className="resume-title">
                                Professional Experience
                            </h3>
                            <div className="resume-item">
                                <h4>Python | Odoo developer</h4>
                                <h5>{new Date().getFullYear()} - Present</h5>
                                <p>
                                    <em>
                                        Heliconia Solutions Pvt Ltd, Vadodara,
                                        Gujarat, IN{" "}
                                    </em>
                                </p>
                                {/* <p> */}
                                <ul>
                                    <li>
                                        Develop and customised odoo Frontend and
                                        Backend development.
                                    </li>
                                    <li>
                                        Also had experience in JS (view) part of
                                        oddo in backend and customised existin
                                        odoo views and make a new views.
                                    </li>
                                    <li>
                                        work with the team of member 4 as a
                                        developer and Develop according
                                        requirements.
                                    </li>
                                    {/* <li>
                                            Oversee the efficient use of
                                            production project budgets ranging
                                            from $2,000 - $25,000
                                        </li> */}
                                </ul>
                                {/* </p> */}
                            </div>
                            <div className="resume-item">
                                <h4>Trainee Python | Odoo developer</h4>
                                <h5>2019</h5>
                                <p>
                                    <em>Sneptech, Gujarat IN</em>
                                </p>
                                <div>
                                    <ul>
                                        <li>
                                            Lear basic fundamental of odoo and
                                            the basic ORP methods.
                                        </li>
                                        <li>
                                            manage and customised the existing
                                            module
                                        </li>
                                        {/* <li>
                                            Recommended and consulted with
                                            clients on the most appropriate
                                            graphic design
                                        </li> */}
                                        {/* <li>
                                            Created 4+ design presentations and
                                            proposals a month for clients and
                                            account managers
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
      }
}

export default Resume
