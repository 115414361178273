import React from 'react';
import SocialLinks from '../const/social_media';
import emailjs from "@emailjs/browser";

class Contact extends React.Component {

    constructor(props) {
        super(props);
        this.ContactForm = React.createRef();
    }

    sendEmail(ev) {
        ev.preventDefault();
        emailjs
            .sendForm(
                "service_p8bpc7c",
                "template_dbrz6ve",
                this.ContactForm.current,
                "user_FQ4FO6nmfNu1QZGlUVld4"
            )
            .then(
                (result) => {
                    console.log(result.text, this.ContactForm.current.email, result);
                },
                (error) => {
                    console.log(error.text);
                }
            );
    }

    render() {
        return (
            <section
                id="contact"
                className={
                    this.props.active_section === "#contact"
                        ? "contact section-show"
                        : "contact"
                }
            >
                <div className="container">
                    <div className="section-title">
                        <h2>Contact</h2>
                        <p>Contact Me</p>
                    </div>

                    <div className="row mt-2">
                        <div className="col-md-6 d-flex align-items-stretch">
                            <div className="info-box">
                                <i className="bx bx-map"></i>
                                <h3>My Address</h3>
                                <p>
                                    <a
                                        href={SocialLinks.address}
                                        style={{ color: "white" }}
                                    >
                                        Ramdevnagr Socitey, Khodiyar nagar road,
                                        Surat, Gujarat, India
                                    </a>
                                </p>
                            </div>
                        </div>

                        <div className="col-md-6 mt-4 mt-md-0 d-flex align-items-stretch">
                            <div className="info-box">
                                <i className="bx bx-share-alt"></i>
                                <h3>Social Profiles</h3>
                                <div className="social-links">
                                    <a
                                        href={SocialLinks.linkedin}
                                        className="linkedin"
                                    >
                                        <i className="bx bxl-linkedin bx-flashing-hover bx-xs" />
                                    </a>
                                    <a
                                        href={SocialLinks.skype}
                                        className="google-plus"
                                    >
                                        <i className="bx bxl-skype bx-flashing-hover bx-xs" />
                                    </a>
                                    <a
                                        href={SocialLinks.twitter}
                                        className="twitter"
                                    >
                                        <i className="bx bxl-twitter bx-flashing-hover bx-xs" />
                                    </a>
                                    <a
                                        href={SocialLinks.facebook}
                                        className="facebook"
                                    >
                                        <i className="bx bxl-facebook bx-flashing-hover bx-xs" />
                                    </a>
                                    <a
                                        href={SocialLinks.instagram}
                                        className="instagram"
                                    >
                                        <i className="bx bxl-instagram bx-flashing-hover bx-xs" />
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/* <div className="col-md-6 mt-4 d-flex align-items-stretch">
                            <div className="info-box">
                                <i className="bx bx-envelope"></i>
                                <h3>Email Me</h3>
                                <p>
                                    <a
                                        href={"mailto:" + SocialLinks.email}
                                        style={{ color: "white" }}
                                    >
                                        {SocialLinks.email}
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 mt-4 d-flex align-items-stretch">
                            <div className="info-box">
                                <i className="bx bx-phone-call"></i>
                                <h3>Call Me</h3>
                                <p>
                                    <a
                                        href={SocialLinks.phoneto}
                                        style={{ color: "white" }}
                                    >
                                        {SocialLinks.phone}
                                    </a>
                                </p>
                            </div>
                        </div> */}
                    </div>

                    <form
                        className="php-email-form mt-4"
                        ref={this.ContactForm}
                        onSubmit={this.sendEmail.bind(this)}
                    >
                        <div className="row">
                            <div className="col-md-6 form-group">
                                <input
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    id="name"
                                    placeholder="Your Name"
                                    data-rule="minlen:4"
                                    data-msg="Please enter at least 4 chars"
                                />
                                <div className="validate"></div>
                            </div>
                            <div className="col-md-6 form-group mt-3 mt-md-0">
                                <input
                                    type="email"
                                    className="form-control"
                                    name="email"
                                    id="email"
                                    placeholder="Your Email"
                                    data-rule="email"
                                    data-msg="Please enter a valid email"
                                />
                                <div className="validate"></div>
                            </div>
                        </div>
                        <div className="form-group mt-3">
                            <input
                                type="text"
                                className="form-control"
                                name="subject"
                                id="subject"
                                placeholder="Subject"
                                data-rule="minlen:4"
                                data-msg="Please enter at least 8 chars of subject"
                            />
                            <div className="validate"></div>
                        </div>
                        <div className="form-group mt-3">
                            <textarea
                                className="form-control"
                                name="message"
                                rows="5"
                                data-rule="required"
                                data-msg="Please write something for us"
                                placeholder="Message"
                            ></textarea>
                            <div className="validate"></div>
                        </div>
                        <div className="mb-3">
                            <div className="loading">Loading</div>
                            <div className="error-message"></div>
                            <div className="sent-message">
                                Your message has been sent. Thank you!
                            </div>
                        </div>
                        <div className="text-center">
                            <button type="submit">Send Message</button>
                        </div>
                    </form>
                </div>
            </section>
        );
    }
}

export default Contact
