import React from "react";
import SocialLinks from "../const/social_media";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_small_screen: window.innerWidth < 992 ? true : false,
            open_mobile_menu: false,
        };
    }

    ResizeWindow() {
        this.setState({
            is_small_screen: window.innerWidth < 992 ? true : false,
            open_mobile_menu: false,
        });
    }


    componentDidMount() {
        window.addEventListener("resize", this.ResizeWindow.bind(this));
    }
    componentWillUnmount() {
        window.addEventListener("resize", this.ResizeWindow.bind(this));
    }

    activatePageSection(event) {
        this.props.UpdateActiveSection(event.currentTarget.id);
        if (this.state.open_mobile_menu) this.setState({ open_mobile_menu: false });
        setTimeout(() => window.scrollTo(0, -100), 60)
    }

    render() {
        return (
            <header
                id="header"
                className={
                    this.props.active_section !== "#header"
                        ? "header-tops header-top"
                        : "header-tops"
                }
            >
                <button
                    type="button"
                    className="mobile-nav-toggle d-lg-none"
                    onClick={() =>
                        this.setState({
                            open_mobile_menu: !this.state.open_mobile_menu,
                        })
                    }
                >
                    <i
                        className={
                            this.state.open_mobile_menu
                                ? "bx bx-x"
                                : "bx bx-menu"
                        }
                    />
                </button>
                <div
                    className={
                        "mobile-nav-overly" +
                        (this.state.open_mobile_menu ? " d-block" : "")
                    }
                />

                <div className="container">
                    <h1>
                        <a href="index.html">Mayank Patel</a>
                    </h1>
                    <h2>
                        I'm a passionate <span>Software Developer</span> from
                        India
                    </h2>
                    <nav
                        className={
                            this.state.is_small_screen
                                ? "mobile-nav" +
                                  (this.state.open_mobile_menu ? " active" : "")
                                : "nav-menu d-none d-lg-block"
                        }
                    >
                        <ul>
                            <li
                                id="#header"
                                className={
                                    this.props.active_section === "#header"
                                        ? "active"
                                        : ""
                                }
                                onClick={this.activatePageSection.bind(this)}
                            >
                                <a href="#header">Home</a>
                            </li>
                            <li
                                id="#about"
                                className={
                                    this.props.active_section === "#about"
                                        ? "active"
                                        : ""
                                }
                                onClick={this.activatePageSection.bind(this)}
                            >
                                <a href="#about">About</a>
                            </li>
                            <li
                                id="#resume"
                                className={
                                    this.props.active_section === "#resume"
                                        ? "active"
                                        : ""
                                }
                                onClick={this.activatePageSection.bind(this)}
                            >
                                <a href="#resume">Resume</a>
                            </li>
                            <li
                                id="#services"
                                className={
                                    this.props.active_section === "#services"
                                        ? "active"
                                        : ""
                                }
                                onClick={this.activatePageSection.bind(this)}
                            >
                                <a href="#services">Services</a>
                            </li>
                            {/* <li onClick={this.activatePageSection.bind(this)}>
                            <a href="#portfolio">
                                Portfolio
                            </a>
                        </li> */}
                            <li
                                id="#contact"
                                className={
                                    this.props.active_section === "#contact"
                                        ? "active"
                                        : ""
                                }
                                onClick={this.activatePageSection.bind(this)}
                            >
                                <a href="#contact">Contact</a>
                            </li>
                        </ul>
                    </nav>
                    <div className="social-links">
                        <a href={SocialLinks.linkedin} className="linkedin">
                            {/* <i className="icofont-linkedin" /> */}
                            <i className="bx bxl-linkedin bx-burst-hover bx-xs" />
                        </a>
                        <a href={SocialLinks.skype} className="google-plus">
                            <i className="bx bxl-skype bx-burst-hover bx-xs" />
                        </a>
                        <a href={SocialLinks.twitter} className="twitter">
                            <i className="bx bxl-twitter bx-burst-hover bx-xs" />
                        </a>
                        <a href={SocialLinks.facebook} className="facebook">
                            <i className="bx bxl-facebook bx-burst-hover bx-xs" />
                        </a>
                        <a href={SocialLinks.instagram} className="instagram">
                            <i className="bx bxl-instagram bx-burst-hover bx-xs" />
                        </a>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;
