// import logo from './logo.svg';
import React from "react";
import "./App.css";
import "./Common.css";

// import $ from "jquery";

import Header from "./components/Header";
import About from "./components/About";
import Resume from "./components/Resume";
import Services from "./components/Services";
// import Portfolio from './components/Portfolio';
import Contact from "./components/Contact";

class App extends React.Component {

    constructor(props) {
		super(props);
		this.state = {
			active_section: "#header"
		}
	}

	UpdateActiveSection(section_name) {
		this.setState({
            active_section: section_name,
        });
	}

    render() {
        return (
            <>
                <Header
                    active_section={this.state.active_section}
                    UpdateActiveSection={this.UpdateActiveSection.bind(this)}
                />
                <About active_section={this.state.active_section} />
                <Resume active_section={this.state.active_section} />
                <Services active_section={this.state.active_section} />
                {/* <Portfolio/> */}
                <Contact active_section={this.state.active_section} />
            </>
        );
    }
}

export default App;
